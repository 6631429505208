.lds-ring {
  display: flex; /* Ensures proper centering */
  justify-content: center;
  align-items: center;
  position: relative;
  width: 120px; /* Default size */
  height: 120px;
}

.lds-ring div {
  position: absolute;
  border: 10px solid var(--clr-medium); /* Default border */
  border-radius: 50%;
  animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(2) {
  border-color: var(--clr-light);
  animation-delay: -0.75s;
}

/* 🔥 Center the overlay properly */
.loading-spinner__overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8); /* Darker backdrop */
  z-index: 9999;
}

/* 🔥 Dynamic Scaling */
.lds-ring[data-size] {
  width: var(--spinner-size);
  height: var(--spinner-size);
}

@keyframes lds-ring {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
